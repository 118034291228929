import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from "@/utils/imagePrefixes";

const GALLERY_PREFIX = "Gallery/";

interface VideoProps {
  desktop: string;
  mobile: string;
}
export interface GalleryAsset {
  id: string;
  title: string;
  asset: IncludePrefixResponsiveSizes;
  mobile: IncludePrefixResponsiveSizes;
  video?: VideoProps;
  alt: string;
}

export const gallery: GalleryAsset[] = [
  {
    id: "ga01",
    title: "Toro Prata",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-2024`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-2024`, "webp"),
    alt: "Fiat Toro na cor prata com grade na cor preta e detalhe em cromado."
  },

  {
    id: "ga02",
    title: "Volante de Fiat Toro",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-volante`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-volante`, "webp"),
    alt: "Interior de Fiat Toro com destaque para seu Volante e controles."
  },
  {
    id: "ga03",
    title: 'Capota Fiat Toro',
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-cacamba`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-cacamba`, "webp"),
    alt: "Vista traseira de Fiat Toro na cor prata com capota rígida fechada."
  },
  {
    id: "ga04",
    title: "Toro Rodas",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-roda`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-roda`, "webp"),
    alt: "Rodas de Fiat Toro na cor prata."
  },

  {
    id: "ga05",
    title: "Grade de Fiat Toro",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-grade-frontal`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-grade-frontal`, "webp"),
    alt: "Grade de Fiat Toro prata na cor preta com detalhe em cromado."
  },
  {
    id: "ga06",
    title: "Acabamento de Fiat Toro",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-banco`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-banco`, "webp"),
    alt: "Vista interna de banco de Fiat Toro com acabamento premium na cor preta e detalhes em vermelho."
  },
  {
    id: "ga07",
    title: "Cabine do motorista Toro",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-multimidia`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-multimidia`, "webp"),
    alt: "Interior de Fiat Toro com destaque para o volante multifuncional e central multimídia."
  },
  {
    id: "ga08",
    title: "Fiat Toro Branca Lateral",
    asset: includeResponsive(`${GALLERY_PREFIX}toro-ranch-cacamba-fechada`, "webp"),
    mobile: includeResponsive(`${GALLERY_PREFIX}/mobile/toro-ranch-cacamba-fechada`, "webp"),
    alt: "Fiat Toro na cor prata vista lateral."
  },
];

export const galleryThumbs = gallery.map((g) => ({
  ...g,
  thumb: `${g.asset.path}-thumb.${g.asset.extension}`,
}));

export const galleryMobileThumbs = gallery.map((g) => ({
  ...g,
  thumbMobile: `${g.mobile.path}-thumb.${g.asset.extension}`,
}));
