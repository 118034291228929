import { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import RampageStore from './RampageStore';

export const getStore = (): RampageStore => new RampageStore();

const StoreContext = createContext({} as RampageStore);

interface IStoreProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: RampageStore;
}

export const StoreProvider: FC<PropsWithChildren<IStoreProvider>> = ({
  value,
  children,
}): JSX.Element => (
  <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
);

export function useMobxStores(): RampageStore {
  return useContext(StoreContext);
}
