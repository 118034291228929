import { productDetails as productDetailsAssets } from "@/assets";
import { MenuLabels } from "@/models";
import { IncludePrefixResponsiveSizes } from "@/utils/imagePrefixes";
import { PRODUCT_DETAILS_REF } from "@/data/menu.data";

export interface ProductTabSlide {
  id: string;
  index?: number;
  title: string;
  alt: string;
  connectme?: boolean;
  slug: string;
  linkBtn?: string;
  labelBtn?: string;
  titleBtn?: string;
  altBtn?: string;
  titleFirst?: string;
  titleSecond?: string;
  highLightedFirst: boolean;
  breakTitle?: boolean;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  parentSlug?: string;
  image: IncludePrefixResponsiveSizes;
  vimeoId?: {
    mobile: string;
    desktop: string;
  };
  tabId: string;
  fileUrl?: string;
}

export interface ProductTab {
  id: string;
  title: string;
  slug: string;
  parentSlug?: string;
  children: Omit<ProductTabSlide, "tabId">[];
}

export const productDetails: ProductTab[] = [
  {
    id: "design",
    title: "Design",
    slug: "design",
    children: [
      {
        id: "nova-grade",
        slug: "nova-grade",
        title: "Nova grade Fiat Toro",
        alt: "Fiat Toro na cor azul com nova grade na cor preta, detalhes cromados e logo da Fiat cromado.",
        highLightedFirst: true,
        parentSlug: "design",
        descriptionDesktop: (
          <p>
            Nova grade em todas as versões, proporcionando um design ainda mais
            incomparável: mais bonito e robusto!
          </p>
        ),
        descriptionMobile: (
          <p>
            Nova grade em todas as versões, proporcionando um design ainda mais
            incomparável: mais bonito e robusto!
          </p>
        ),
        image: productDetailsAssets.design.novaGrade,
      },
      {
        id: "liga-leve-e-farois-full-led",
        slug: "liga-leve-e-farois-full-led",
        title: "Rodas Aro 18”",
        alt: "Fiat Toro na cor azul vista lateral com destaque para rodas cromadas aro 18.",
        highLightedFirst: true,
        parentSlug: "design",
        descriptionDesktop: (
          <p>
            Presentes nas versões Volcano e Ranch, as rodas de aro 18” trazem
            uma beleza e imponência incomparáveis para a Fiat Toro.
          </p>
        ),
        descriptionMobile: (
          <p>
            Presentes nas versões Volcano e Ranch, as rodas de aro 18” trazem
            uma beleza e imponência incomparáveis para a Fiat Toro.
          </p>
        ),
        image: productDetailsAssets.design.farolLed,
      },
      {
        id: "overbumper-integrado",
        slug: "overbumper-integrado",
        title: "Barra de proteção integrada Toro",
        alt: "Fiat Toro na cor cinza vista frontal com destaque para dianteira com barra de proteção integrada.",
        highLightedFirst: true,
        parentSlug: "performance",
        descriptionDesktop: (
          <p>
            O overbumper integrado é uma barra de proteção integrada ao
            para-choque que além de proteger ainda deixa sua picape mais
            imponente e robusta.
          </p>
        ),
        descriptionMobile: (
          <p>
            O overbumper integrado é uma barra de proteção integrada ao para-
            <br />
            choque que além de proteger ainda deixa sua picape mais imponente
            <br /> e robusta.
          </p>
        ),
        image: productDetailsAssets.design.overbumper,
      },
    ],
  },
  {
    id: "conforto",
    title: "Conforto",
    slug: "conforto",
    children: [
      {
        id: "bancos-couro",
        slug: "bancos-couro",
        highLightedFirst: true,
        parentSlug: "conforto",
        title: "Bancos Premium Toro",
        alt: "Interior de Fiat Toro como bancos em couro marrom e acabamento premium.",
        descriptionDesktop: (
          <p>
            O acabamento premium da nova Fiat Toro oferece conforto único em
            todo o seu espaço interno, desde os bancos em couro com ajuste
            elétrico até o dashboard altamente tecnológico.
          </p>
        ),
        descriptionMobile: (
          <p>
            O acabamento premium da nova Fiat Toro oferece conforto único em
            todo o seu espaço interno, desde os bancos em couro com ajuste
            elétrico até o dashboard altamente tecnológico.
          </p>
        ),
        image: productDetailsAssets.consforto.bancosCouro,
      },
      {
        id: "suspensão-multilink",
        slug: "suspensão-multilink",
        highLightedFirst: true,
        parentSlug: "conforto",
        title: "Suspensão Multilink Toro",
        alt: "Fiat Toro na cor preta vista traseira.",
        descriptionDesktop: (
          <p>
            Suspensão Multilink que proporciona muito mais força, sem abrir mão
            do conforto.
          </p>
        ),
        descriptionMobile: (
          <p>
            Suspensão Multilink que proporciona muito mais força,
            <br /> sem abrir mão do conforto.
          </p>
        ),
        image: productDetailsAssets.consforto.multilink,
      },
      {
        id: "volantes-couro",
        slug: "volantes-couro",
        highLightedFirst: true,
        parentSlug: "conforto",
        title: "Volante em couro Toro",
        alt: "Interior de Fiat Toro com volante em couro preto e acabamento premium.",
        descriptionDesktop: (
          <p>
            Aproveite ainda mais conforto e sofisticação para o interior da sua
            Toro com o revestimento em couro do volante.
          </p>
        ),
        descriptionMobile: (
          <p>
            Aproveite ainda mais conforto e sofisticação para o interior da sua
            Toro com o revestimento em couro do volante.
          </p>
        ),
        image: productDetailsAssets.consforto.volanteCouro,
      },
    ],
  },
  {
    id: "performance-carga",
    title: "Performance e Carga",
    slug: "performance-carga",
    children: [
      {
        id: "motor",
        slug: "motor",
        title: "Motor TurboFlex Toro",
        alt: "Imagem de motor Turbo Flex de Fiat Toro em fundo preto.",
        highLightedFirst: true,
        parentSlug: "performance-carga",
        descriptionDesktop: (
          <p>
            Com 185CV , o Motor Turbo Flex tem um torque de 270Nm com baixo
            consumo de combustível. A partir de 1500 RPM, você sente o seu alto
            desempenho, principalmente nas retomadas.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com 185CV , o Motor Turbo Flex tem um torque de 270Nm com baixo
            consumo de combustível. A partir de 1500 RPM, você sente o seu alto
            desempenho, principalmente nas retomadas.
          </p>
        ),
        image: productDetailsAssets.performance_carga.motor,
      },
      {
        id: "tracao-picape",
        slug: "tracao-picape",
        highLightedFirst: true,
        parentSlug: "performance-carga",
        title: "Seletor de tração Toro",
        alt: "Botões seletores de tração de Fiat Toro na cor preta e grifados em branco: “4wd”, 4wdlow“ e veículo em terreno em declive.",
        descriptionDesktop: (
          <p>
            Pronta para qualquer terreno e com versões pensadas para cada tipo
            de necessidade: versões com Trações 4x2 (flex) e 4x4 (Diesel).
          </p>
        ),
        descriptionMobile: (
          <p>
            Pronta para qualquer terreno e com versões pensadas para cada tipo
            de necessidade: versões com Trações 4x2 (flex) e 4x4 (Diesel).
          </p>
        ),
        image: productDetailsAssets.performance_carga.tracao,
      },
      {
        id: "carga",
        slug: "carga",
        highLightedFirst: true,
        parentSlug: "performance-carga",
        title: "Capacidade de Carga Toro",
        alt: "Fiat Toro na cor azul vista lateral e traseira. Em sua caçamba estão duas motos, uma azul e uma verde.",
        descriptionDesktop: (
          <p>
            Conte com a capacidade de carga de até 1 tonelada, caçamba com 937L
            de volume, 750kg de capacidade de carga (Flex) e 1 Tonelada
            (Diesel). Além disso, garanta 400kg de capacidade de reboque.
          </p>
        ),
        descriptionMobile: (
          <p>
            Conte com a capacidade de carga de até 1 tonelada, caçamba com 937L
            de volume, 750kg de capacidade de carga (Flex) e 1 Tonelada
            (Diesel). Além disso, garanta 400kg de capacidade de reboque.
          </p>
        ),
        image: productDetailsAssets.performance_carga.capacidade,
      },
    ],
  },
  {
    id: "tecnologia",
    title: "Tecnologia",
    slug: "tecnologia",
    children: [
      {
        id: "super-conectada",
        slug: "super-conectada",
        title: "Central Multimidia de Toro",
        alt: "Interior de Fiat Toro com destaque para central multimídia de 10 polegadas.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            Nova central multimídia 10” vertical, além de cluster full <br />
            digital de 7”, carregamento sem fio para smartphones.
          </p>
        ),
        descriptionMobile: (
          <p>
            Nova central multimídia 10” vertical, além de cluster full digital
            de 7”, carregamento sem fio para smartphones.
          </p>
        ),
        image: productDetailsAssets.tecnologia.multimidaa,
      },
      {
        id: "ar-condicionado-digital-dual-zone",
        slug: "ar-condicionado-digital-dual-zone",
        title: "Ar-condicionado dual zone",
        alt: "Interior de Fiat Toro com destaque para controles do ar-condicionado dual zone.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            Ar condicionado digital dual zone com controles touch screen,
            proporcionando ainda mais conforto para quem vai no banco de trás.
          </p>
        ),
        descriptionMobile: (
          <p>
            Ar condicionado digital dual zone com controles touch screen,
            proporcionando ainda mais conforto para quem vai no banco de trás.
          </p>
        ),
        image: productDetailsAssets.tecnologia.ar,
      },
      {
        id: "keyless-entry-and-go",
        slug: "keyless-entry-and-go",
        title: "Botão Start/Stop",
        alt: "Interior de Fiat Toro com destaque botão de ligar e desligar o veículo na cor preta.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            Sua picape reconhece sua presença ao carregar as chaves e abre as
            portas automaticamente ao tocar o botão na maçaneta. Ele também
            proporciona que você dê a partida utilizando o botão START/STOP ao
            entrar no carro, reconhecendo a presença da chave dentro do veículo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Sua picape reconhece sua presença ao carregar as chaves e abre as
            portas automaticamente ao tocar o botão na maçaneta. Ele também
            proporciona que você dê a partida utilizando o botão START/STOP ao
            entrar no carro, reconhecendo a presença da chave dentro do veículo.
          </p>
        ),
        image: productDetailsAssets.tecnologia.keyless,
      },
      {
        id: "connect-me",
        slug: "connect-me",
        title: "Fiat Connect Me",
        alt: "Smartphone acessando o aplicativo Fiat Connect////Me.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        connectme: true,
        descriptionDesktop: (
          <p>
            Conte com 12 meses com o Fiat de Connect////Me grátis, garantindo
            wi-fi alocado no veículo, alertas de condução, controles remotos,
            localização e rota de chegada, alerta e assistente de recuperação em
            caso de roubo e furto e muito mais.
          </p>
        ),
        descriptionMobile: (
          <p>
            Conte com 12 meses com o Fiat de Connect////Me grátis, garantindo
            wi-fi alocado no veículo, alertas de condução, controles remotos,
            localização e rota de chegada, alerta e assistente de recuperação em
            caso de roubo e furto e muito mais.
          </p>
        ),
        image: productDetailsAssets.tecnologia.connectme,
      },
      {
        id: "frenagem-automatica",
        slug: "frenagem-automatica",
        title: "Frenagem automática",
        alt: "Imagem da tela da central multimídia de Fiat Toro ao acionar o sistema de frenagem automática do veículo.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            A tecnologia funciona através de sensores e câmeras, que detectam
            potencial risco de colisão. Automaticamente, os freios são acionados
            para reduzir a gravidade do acidente ou, idealmente, evitá-lo
            completamente.
          </p>
        ),
        descriptionMobile: (
          <p>
            A tecnologia funciona através de sensores e câmeras, que detectam
            potencial risco de colisão. Automaticamente, os freios são acionados
            para reduzir a gravidade do acidente ou, idealmente, evitá-lo
            completamente.
          </p>
        ),
        image: productDetailsAssets.tecnologia.frenagemAt,
      },
      {
        id: "aviso-de-saida-de-pista",
        slug: "aviso-de-saida-de-pista",
        title: "Aviso de saída de faixa",
        alt: "Imagem da tela da central multimídia de Fiat Toro ao acionar o sistema de aviso de saída de faixa.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            O aviso de saída de faixa é um sistema de segurança que alerta o
            motorista quando o carro sai de sua faixa. Assim, quando o sensor
            detecta que um carro parece estar saindo de sua faixa, um alarme é
            acionado, proporcionando mais segurança para os passageiros e
            motorista.
          </p>
        ),
        descriptionMobile: (
          <p>
            O aviso de saída de faixa é um sistema de segurança que alerta o
            motorista quando o carro sai de sua faixa. Assim, quando o sensor
            detecta que um carro parece estar saindo de sua faixa, um alarme é
            acionado, proporcionando mais segurança para os passageiros e
            motorista.
          </p>
        ),
        image: productDetailsAssets.tecnologia.peermanenciaFaixa,
      },
      {
        id: "comutação-automática-farol-alto",
        slug: "comutação-automática-farol-alto",
        title:
          "Representação visual do funcionamento de sensores  que mudam a intensidade dos faróis segundo a necessidade.",
        alt: "",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            O sistema de farol alto com comutação automática utiliza uma câmera
            para detectar as condições de iluminação ambiente, incluindo a
            presença dos faróis vindo em direção do veículo ou as luzes,
            evitando acidentes.
          </p>
        ),
        descriptionMobile: (
          <p>
            O sistema de farol alto com comutação automática utiliza uma câmera
            para detectar as condições de iluminação ambiente, incluindo a
            presença dos faróis vindo em direção do veículo ou as luzes,
            evitando acidentes.
          </p>
        ),
        image: productDetailsAssets.tecnologia.comutacao,
      },
      {
        id: "6-airbags",
        slug: "6-airbags",
        title: "Airbags Toro",
        alt: "​Interior de Fiat Toro com destaque para letreiro “SRS Airbags” em preto grifado sob acabamento do painel da cabine do motorista.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            Com a Toro a segurança vem em primeiro lugar. Por isso, sua picape
            vem equipada com 6 airbags: 2 laterais, 2 no estilo cortina e 2
            frontais.
          </p>
        ),
        descriptionMobile: (
          <p>
            Com a Toro a segurança vem em primeiro lugar. Por isso, sua picape
            vem equipada com 6 airbags: 2 laterais, 2 no estilo cortina e 2
            frontais.
          </p>
        ),
        image: productDetailsAssets.tecnologia.airbag,
      },
      {
        id: "controle-de-tracao-e-sensor-dianteiro",
        slug: "controle-de-tracao-e-sensor-dianteiro",
        title: "Controle de Tração Toro",
        alt: "Representação gráfica de controle de tração de Fiat Toro que auxilia a manter o veículo em seu curso, evitando acidentes.",
        highLightedFirst: true,
        parentSlug: "tecnologia",
        descriptionDesktop: (
          <p>
            Sua Toro detectará perdas de tração durante a aceleração e ajudará
            no controle do carro, além disso conte com sensores dianteiros que
            auxiliam na hora de estacionar e também evitam colisões.
          </p>
        ),
        descriptionMobile: (
          <p>
            Sua Toro detectará perdas de tração durante a aceleração e ajudará
            no controle do carro, além disso conte com sensores dianteiros que
            auxiliam na hora de estacionar e também evitam colisões.
          </p>
        ),
        image: productDetailsAssets.tecnologia.controleTracao,
      },
    ],
  },
  {
    id: "servicos",
    title: "Serviços",
    slug: "servicos",
    children: [
      {
        id: "revisão-sob-medida",
        slug: "revisão-sob-medida",
        title: "Revisão sob medida",
        alt: "Especialista Fiat vestido de macacão azul e luvas fazendo a manutenção de Fiat Toro.",
        highLightedFirst: true,
        parentSlug: "servicos",
        descriptionDesktop: (
          <p>
            Planos de revisões que você pode montar com desconto e preços fixos.
            Mais economia para você e sua Toro sempre bem cuidada!
          </p>
        ),
        descriptionMobile: (
          <p>
            Planos de revisões que você pode montar com desconto e preços fixos.
            Mais economia para você e sua Toro sempre bem cuidada!
          </p>
        ),
        image: productDetailsAssets.servicos.revisoes,
      },
      {
        id: "cuidado-que-seu-fiat-merece",
        slug: "cuidado-que-seu-fiat-merece",
        title: "Serviços Fiat",
        alt: "Especialista Fiat vestido de macacão azul e luvas segurando prancheta na mão esquerda e fazendo o sinal de joia com a outra mão.",
        highLightedFirst: true,
        parentSlug: "servicos",
        descriptionDesktop: (
          <p>
            Conheça os pacotes de serviços FIAT Flex. A sua Toro pode ter até 5
            anos de garantia além das Revisões sob medida com desconto e preço
            fixo.
          </p>
        ),
        descriptionMobile: (
          <p>
            Conheça os pacotes de serviços FIAT Flex. A sua Toro pode ter até 5
            anos de garantia além das Revisões sob medida com desconto e preço
            fixo.
          </p>
        ),
        image: productDetailsAssets.servicos.fiatFlex,
      },
    ],
  },
  {
    id: "acessorios",
    title: "Acessórios",
    slug: "acessorios",
    children: [
      {
        id: "capota-eletrica",
        slug: "capota-eletrica",
        title: "Capota elétrica",
        alt: "Fiat Toro na cor azul vista traseira com capota elétrica na cor preta instalada e mão de motorista segurando o controle que aciona o acessório.",
        highLightedFirst: true,
        parentSlug: "acessorios",
        descriptionDesktop: (
          <p>
            Feita em material rígido e resistente, oferece maior proteção contra
            infiltração de água e ruídos vindos da caçamba, além de abertura e
            fechamento mais práticos. maior proteção contra infiltração de água
            e ruídos vindos da caçamba, além de abertura e fechamento mais
            práticos.
          </p>
        ),
        descriptionMobile: (
          <p>
            Feita em material rígido e resistente, oferece maior proteção contra
            infiltração de água e ruídos vindos da caçamba, além de abertura e
            fechamento mais práticos. maior proteção contra infiltração de água
            e ruídos vindos da caçamba, além de abertura e fechamento mais
            práticos.
          </p>
        ),
        image: productDetailsAssets.acessorios.capota,
      },
      {
        id: "extensor-de-cacamba",
        slug: "extensor-de-cacamba",
        title: "Extensor de caçamba",
        alt: "Fiat Toro na cor branca vista traseira com extensor de caçamba instalado.",
        highLightedFirst: true,
        parentSlug: "acessorios",
        descriptionDesktop: (
          <p>
            Extensor Caçamba Fiat Toro é super prático para instalar e suporta
            até 250 kg de carga estática, aumentando a capacidade volumétrica de
            carga da sua Toro em 400 litros. Aumenta, assim, o tamanho da
            caçamba em 70 cm. <br /> Esse é o acessório que faltava para deixar
            sua Toro muito mais versátil e atende o espírito do universo das
            picapes.
          </p>
        ),
        descriptionMobile: (
          <p>
            Extensor Caçamba Fiat Toro é super prático para instalar e suporta
            até 250 kg de carga estática, aumentando a capacidade volumétrica de
            carga da sua Toro em 400 litros. Aumenta, assim, o tamanho da
            caçamba em 70 cm. Esse é o acessório que faltava para deixar sua
            Toro muito mais versátil e atende o espírito do universo das
            picapes.
          </p>
        ),
        image: productDetailsAssets.acessorios.extensorCacamba,
      },
      {
        id: "engate-reboque",
        slug: "engate-reboque",
        title: "Engate reboque Toro",
        alt: "Engate instalado em Fiat Toro.",
        highLightedFirst: true,
        parentSlug: "acessorios",
        descriptionDesktop: (
          <p>
            Forte e durável, o engate acopla de forma fácil e firme. Funcional
            para puxar reboques, carretinhas de transporte ou trailers.
          </p>
        ),
        descriptionMobile: (
          <p>
            Forte e durável, o engate acopla de forma fácil e firme. Funcional
            para puxar reboques, carretinhas de transporte ou trailers.
          </p>
        ),
        image: productDetailsAssets.acessorios.engateReboque,
      },
      {
        id: "estribo-tubular",
        slug: "estribo-tubular",
        title: "Estribo tubular",
        alt: "Estribo lateral instalado em Fiat Toro na cor azul visto lateralmente.",
        highLightedFirst: true,
        parentSlug: "acessorios",
        descriptionDesktop: (
          <p>
            Mais estilo e funcionalidade. Este resistente estribo lateral deixa
            o visual mais off-road e ajuda no acesso ao veículo. É um acessório
            útil para auxiliar crianças e idosos.
          </p>
        ),
        descriptionMobile: (
          <p>
            Mais estilo e funcionalidade. Este resistente estribo lateral deixa
            o visual mais off-road e ajuda no acesso ao veículo. É um acessório
            útil para auxiliar crianças e idosos.
          </p>
        ),
        image: productDetailsAssets.acessorios.estriboTubular,
      },
    ],
  },
];

export const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
  return pDetails.reduce<MenuLabels[]>((prev, item) => {
    return [
      ...prev,
      {
        numberId: 0,
        id: `data-${item.id}`,
        label: item.title,
        slug: item.slug,
        url: `/${item.slug}`,
        prefixRelation: PRODUCT_DETAILS_REF,
        isDetails: true,
      },
    ];
  }, []);
};

let index = -1;

export const allSlides: ProductTabSlide[] = productDetails.reduce(
  (prev, curr) => {
    return [
      ...prev,
      ...curr.children.map((c) => {
        index++;
        return {
          ...c,
          index,
          parentSlug: curr.slug,
          tabId: curr.id,
        };
      }),
    ];
  },
  [] as ProductTabSlide[]
);

export default productDetails;
