import { Context } from '@/context';
import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, useContext } from 'react';

interface ConditionalProps {
  notOn?: 'desktop' | 'mobile';
  desktop?: JSX.Element | null;
  mobile?: JSX.Element | null;
  condition?: boolean;
}

const Conditional: FC<PropsWithChildren<ConditionalProps>> = observer(
  ({ notOn, mobile, desktop, condition, children }) => {
    const { state } = useContext(Context);
    if (condition !== null && condition) {
      return <>{children}</>;
    }
    if ((notOn === 'desktop' || !!mobile) && !state.layout.isDesktop) {
      return <>{children ?? mobile}</>;
    }
    if ((notOn === 'mobile' || !!desktop) && state.layout.isDesktop) {
      return <>{children ?? desktop}</>;
    }
    return null;
  }
);

export default Conditional;
