import { FC } from "react";

export const ConnectMeMobile: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      id="connect-fiat"
      xmlns="http://www.w3.org/2000/svg"
      width="307.883"
      height="49.848"
      viewBox="0 0 307.883 49.848"
      style={{ zIndex: 3, position: "relative" }}
      className={className}
    >
      <path
        id="Caminho_1162"
        data-name="Caminho 1162"
        d="M1157.678,1436.706H1147.8v5.343h9.33v7.04h-9.33v5.513h9.881v7.041h-18.194v-31.979h18.194Z"
        transform="translate(-849.795 -1428.774)"
        fill="#fff"
      />
      <g
        id="Grupo_3363"
        data-name="Grupo 3363"
        transform="translate(206.417 0.942)"
      >
        <path
          id="Caminho_1163"
          data-name="Caminho 1163"
          d="M941.557,1461.717h-4.581l10.256-31.927h4.58Z"
          transform="translate(-936.976 -1429.79)"
          fill="#fff"
        />
        <path
          id="Caminho_1164"
          data-name="Caminho 1164"
          d="M965.166,1461.717h-4.581l10.255-31.927h4.58Z"
          transform="translate(-950.878 -1429.79)"
          fill="#fff"
        />
        <path
          id="Caminho_1165"
          data-name="Caminho 1165"
          d="M988.774,1461.717h-4.581l10.255-31.927h4.58Z"
          transform="translate(-964.777 -1429.79)"
          fill="#fff"
        />
        <path
          id="Caminho_1166"
          data-name="Caminho 1166"
          d="M1012.05,1461.717h-4.581l10.255-31.927h4.581Z"
          transform="translate(-978.482 -1429.79)"
          fill="#fff"
        />
      </g>
      <path
        id="Caminho_1167"
        data-name="Caminho 1167"
        d="M459.47,1439.078a8.87,8.87,0,0,0-7.125-3.52,8.731,8.731,0,0,0-3.456.678,8.287,8.287,0,0,0-4.538,4.624,9.1,9.1,0,0,0-.658,3.478,9.212,9.212,0,0,0,.658,3.52,8.476,8.476,0,0,0,4.559,4.665,8.448,8.448,0,0,0,3.393.679,9.624,9.624,0,0,0,7.167-3.393v9.839l-.848.3a24.517,24.517,0,0,1-3.563,1,17.254,17.254,0,0,1-3.266.318,16.686,16.686,0,0,1-11.684-4.771,17.476,17.476,0,0,1-3.711-5.387,17.269,17.269,0,0,1-.021-13.571,16.889,16.889,0,0,1,15.459-10.031,17.553,17.553,0,0,1,3.754.4,22.773,22.773,0,0,1,3.88,1.251Z"
        transform="translate(-434.999 -1427.5)"
        fill="#fff"
      />
      <path
        id="Caminho_1168"
        data-name="Caminho 1168"
        d="M502.347,1444.38a16.481,16.481,0,0,1,10.539-15.586,20.326,20.326,0,0,1,14.271,0,16.862,16.862,0,0,1,5.62,3.584,16.407,16.407,0,0,1,3.669,5.364,17.419,17.419,0,0,1,0,13.275,16.392,16.392,0,0,1-3.669,5.365,16.835,16.835,0,0,1-5.62,3.583,20.319,20.319,0,0,1-14.271,0,16.537,16.537,0,0,1-10.539-15.585Zm8.695,0a8.512,8.512,0,0,0,.721,3.52,8.807,8.807,0,0,0,4.813,4.644,9.414,9.414,0,0,0,6.913,0,9.051,9.051,0,0,0,2.885-1.845,8.741,8.741,0,0,0,1.972-2.8,8.955,8.955,0,0,0,0-7.04,8.758,8.758,0,0,0-1.972-2.8,9.079,9.079,0,0,0-2.885-1.846,9.43,9.43,0,0,0-6.913,0,8.813,8.813,0,0,0-4.813,4.645A8.513,8.513,0,0,0,511.042,1444.38Z"
        transform="translate(-474.653 -1427.5)"
        fill="#fff"
      />
      <path
        id="Caminho_1169"
        data-name="Caminho 1169"
        d="M686.678,1461.643v-31.979h8.313l15.352,19.552v-19.552h8.27v31.979h-8.27l-15.352-19.551v19.551Z"
        transform="translate(-583.186 -1428.774)"
        fill="#fff"
      />
      <path
        id="Caminho_1170"
        data-name="Caminho 1170"
        d="M598.416,1461.643v-31.979h8.313l15.352,19.552v-19.552h8.27v31.979h-8.27l-15.352-19.551v19.551Z"
        transform="translate(-531.218 -1428.774)"
        fill="#fff"
      />
      <path
        id="Caminho_1171"
        data-name="Caminho 1171"
        d="M793.253,1436.706h-9.882v5.343H792.7v7.04h-9.331v5.513h9.882v7.041H775.059v-31.979h18.194Z"
        transform="translate(-635.224 -1428.774)"
        fill="#fff"
      />
      <path
        id="Caminho_1172"
        data-name="Caminho 1172"
        d="M852.233,1439.078a8.872,8.872,0,0,0-7.125-3.52,8.735,8.735,0,0,0-3.457.678,8.289,8.289,0,0,0-4.537,4.624,9.1,9.1,0,0,0-.659,3.478,9.2,9.2,0,0,0,.659,3.52,8.49,8.49,0,0,0,7.952,5.344,9.625,9.625,0,0,0,7.167-3.393v9.839l-.848.3a24.557,24.557,0,0,1-3.562,1,17.276,17.276,0,0,1-3.267.318,16.685,16.685,0,0,1-11.685-4.771,17.494,17.494,0,0,1-3.711-5.387,17.27,17.27,0,0,1-.022-13.571A16.888,16.888,0,0,1,844.6,1427.5a17.542,17.542,0,0,1,3.754.4,22.749,22.749,0,0,1,3.88,1.251Z"
        transform="translate(-666.255 -1427.5)"
        fill="#fff"
      />
      <path
        id="Caminho_1173"
        data-name="Caminho 1173"
        d="M907.3,1436.706v24.937h-8.313v-24.937h-6.828v-7.041h21.971v7.041Z"
        transform="translate(-704.171 -1428.774)"
        fill="#fff"
      />
      <path
        id="Caminho_1174"
        data-name="Caminho 1174"
        d="M1031.826,1461.644,1042,1429.666h8.228l3.875,17.05,8.891-17.05h8.227l.137,31.978h-7.881l.133-18.406-10.279,18.406h-3.308l-4.438-18.406-5.486,18.406Z"
        transform="translate(-786.407 -1428.775)"
        fill="#fff"
      />
      <g
        id="Grupo_3364"
        data-name="Grupo 3364"
        transform="translate(245.197 37.252)"
      >
        <rect
          id="Retângulo_1886"
          data-name="Retângulo 1886"
          width="1.854"
          height="12.419"
          transform="translate(4.355 0)"
          fill="#fff"
        />
        <path
          id="Caminho_1175"
          data-name="Caminho 1175"
          d="M1031.286,1530.514h1.853v-5.622h1.356v-1.439h-1.356v-3.927h1.741v-1.432h-3.594Z"
          transform="translate(-1031.286 -1518.094)"
          fill="#fff"
        />
        <path
          id="Caminho_1176"
          data-name="Caminho 1176"
          d="M1061.232,1518.093v1.432h1.326l.007,10.988h1.832l-.007-10.988h1.34v-1.432Z"
          transform="translate(-1048.918 -1518.093)"
          fill="#fff"
        />
        <path
          id="Caminho_1177"
          data-name="Caminho 1177"
          d="M1050.472,1523.459v-3.949h.905a10.7,10.7,0,0,1,.685,2.449c.143.815.218,1.5.218,1.5Zm2.036-5.366h-3.7v12.42h1.688V1524.9h1.862c.166,1.026.18,5.282.18,5.615h1.712c.052-3.158.022-9.028-1.741-12.42"
          transform="translate(-1041.602 -1518.093)"
          fill="#fff"
        />
      </g>
      <path
        id="Caminho_1178"
        data-name="Caminho 1178"
        d="M1083.452,1518.449v1.487a2.564,2.564,0,0,0-1.066-.31,1.739,1.739,0,0,0-1.011.29,2.12,2.12,0,0,0-.7.9,5.691,5.691,0,0,0-.405,1.531,15.872,15.872,0,0,0-.13,2.192q0,4.55,2.33,4.55a2.488,2.488,0,0,0,.979-.255v1.52a2.689,2.689,0,0,1-1.337.334q-3.73,0-3.73-6.093a10.243,10.243,0,0,1,.91-4.9,3,3,0,0,1,2.788-1.607A2.855,2.855,0,0,1,1083.452,1518.449Z"
        transform="translate(-813.82 -1480.835)"
        fill="#fff"
      />
      <path
        id="Caminho_1179"
        data-name="Caminho 1179"
        d="M1099.121,1524.7a10.63,10.63,0,0,1-.724,4.622,2.527,2.527,0,0,1-2.426,1.368,2.586,2.586,0,0,1-2.522-1.471,12.548,12.548,0,0,1-.723-5.114,10.753,10.753,0,0,1,.716-4.638,2.547,2.547,0,0,1,2.45-1.367,2.614,2.614,0,0,1,2.29,1.058,5,5,0,0,1,.716,2.012A20.92,20.92,0,0,1,1099.121,1524.7Zm-1.734.175a19.592,19.592,0,0,0-.287-4.232q-.287-1.049-1.168-1.049a1.172,1.172,0,0,0-1.177.962,16.755,16.755,0,0,0-.287,3.9,15.468,15.468,0,0,0,.3,3.738,1.187,1.187,0,0,0,1.161.988,1.16,1.16,0,0,0,1.144-.923A12.176,12.176,0,0,0,1097.386,1524.88Z"
        transform="translate(-822.264 -1480.847)"
        fill="#fff"
      />
      <path
        id="Caminho_1180"
        data-name="Caminho 1180"
        d="M1111.195,1518.489h1.542l1.647,4.9a44.506,44.506,0,0,1,1.264,4.432q-.2-1.425-.294-2.578t-.095-2.171v-4.582h1.662v12.282h-1.551l-1.853-5.528q-.254-.772-.481-1.583t-.418-1.7c-.01-.075-.027-.165-.052-.27s-.051-.228-.083-.366c.016.138.029.257.04.354s.018.182.023.251l.088,1.249.088,1.519c.006.085.009.188.012.31s0,.26,0,.414l.088,5.354h-1.63Z"
        transform="translate(-833.139 -1481.074)"
        fill="#fff"
      />
      <path
        id="Caminho_1181"
        data-name="Caminho 1181"
        d="M1127.889,1518.489h1.543l1.647,4.9a44.54,44.54,0,0,1,1.264,4.432q-.2-1.425-.294-2.578t-.1-2.171v-4.582h1.662v12.282h-1.551l-1.854-5.528q-.254-.772-.481-1.583t-.417-1.7c-.01-.075-.028-.165-.052-.27s-.051-.228-.083-.366c.016.138.029.257.04.354s.019.182.024.251l.088,1.249.087,1.519c0,.085.008.188.012.31s0,.26,0,.414l.087,5.354h-1.63Z"
        transform="translate(-842.968 -1481.074)"
        fill="#fff"
      />
      <path
        id="Caminho_1182"
        data-name="Caminho 1182"
        d="M1144.584,1518.489h4.4v1.4h-2.744v3.889h2.434v1.4h-2.434v4.193h2.744v1.4h-4.4Z"
        transform="translate(-852.798 -1481.074)"
        fill="#fff"
      />
      <path
        id="Caminho_1183"
        data-name="Caminho 1183"
        d="M1162.374,1518.449v1.487a2.561,2.561,0,0,0-1.066-.31,1.736,1.736,0,0,0-1.01.29,2.111,2.111,0,0,0-.7.9,5.606,5.606,0,0,0-.406,1.531,15.842,15.842,0,0,0-.131,2.192q0,4.55,2.33,4.55a2.49,2.49,0,0,0,.979-.255v1.52a2.685,2.685,0,0,1-1.336.334q-3.731,0-3.731-6.093a10.242,10.242,0,0,1,.911-4.9,3,3,0,0,1,2.789-1.607A2.849,2.849,0,0,1,1162.374,1518.449Z"
        transform="translate(-860.289 -1480.835)"
        fill="#fff"
      />
      <path
        id="Caminho_1184"
        data-name="Caminho 1184"
        d="M1170.636,1518.489h5.384v1.448h-1.853v10.834h-1.622v-10.834h-1.909Z"
        transform="translate(-868.137 -1481.074)"
        fill="#fff"
      />
      <path
        id="Caminho_1185"
        data-name="Caminho 1185"
        d="M996.793,1523h1.9q2.232,0,2.233,2.772a3.3,3.3,0,0,1-.291,1.572,1.817,1.817,0,0,1-1.026.776,2.231,2.231,0,0,1,1.154.862,3.062,3.062,0,0,1,.318,1.566q0,2.865-2.455,2.866h-1.835Zm1.322,4.587a1.421,1.421,0,0,0,1.133-.378,2.306,2.306,0,0,0,.316-1.4q0-1.686-1.214-1.685h-.168a.178.178,0,0,0-.067.006Zm0,4.627a1.551,1.551,0,0,0,1.193-.372,2.048,2.048,0,0,0,.331-1.342,2.167,2.167,0,0,0-.344-1.4,1.471,1.471,0,0,0-1.181-.4Z"
        transform="translate(-765.78 -1483.728)"
        fill="#fff"
      />
      <path
        id="Caminho_1186"
        data-name="Caminho 1186"
        d="M1007.645,1523h1.484l.688,2.266c.022.068.044.142.067.222s.049.169.081.263a16.188,16.188,0,0,1,.525,2.064c.082-.557.149-.948.2-1.167l.216-.932.081-.363.579-2.354h1.464l-1.814,5.936v4.478h-1.4v-4.478Z"
        transform="translate(-772.169 -1483.728)"
        fill="#fff"
      />
    </svg>
  );
};
