import { brand, ui } from "@/assets";
import { PAGE_TITLE, brandLinks, project } from "@/constants";
import { Context } from "@/context";
import useDocumentScrollThrottled from "@/hooks/useDocumentScrollThrottled";
import scssStyles from "@/utils/scssStyles";
import { HamburguerButton } from "@components/index";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { FC, useContext, useState } from "react";
import styles from "./Header.module.scss";
import DataLayer from "@utils/Datalayer";

const Header: FC = observer(() => {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const { dispatch, state } = useContext(Context);

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 400;

  let timeout: NodeJS.Timeout;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div
      className={scssStyles([
        styles.container,
        shouldHideHeader ? styles.hide : "",
      ])}
      style={{ top: state.layout.bannerActive ? "80px" : 0 }}
    >
      <div className={scssStyles([styles.mobile])}>
        <HamburguerButton
          title="Abrir menu"
          handleClick={() => {
            const open = !state.layout.menuOpen;
            dispatch({
              type: "SET_MENU_OPEN",
              payload: { menuOpen: open },
            });
            DataLayer.toggleEvent({
              elementCategory: 'icone',
              element: 'menu',
              pageSection: 'header',
              pageSubsection: 'menu',
            }, open);
          }}
        >
          <img src={ui.menuClosed} alt="Menu" />
        </HamburguerButton>
        <div>
          <a
            target="_blank"
            href={brandLinks.mainUrl}
            title={PAGE_TITLE}
            className={styles.title}
          >
            <img
              src={brand.logoHeaderMobile}
              alt={project.brand}
              className={styles.logoHeader}
            />
          </a>
        </div>
        <div />
      </div>
    </div>
  );
});

export default Header;
