import styles from './WhatsappBtn.module.scss';
import { brand } from '@/assets';
import { links } from '@/data/menu.data';
import DataLayer from '@/utils/Datalayer';
import Whatsapp from '@/utils/whatsapp';

import { FC } from 'react';

interface WhatsappBtnProps {
  id?: string;
}

const WhatsappBtn: FC<WhatsappBtnProps> = ({ id = 'btnWhatsapp' }) => {
  return (
    <button
      className={styles.whatsapp_float}
      onClick={() => {
        const url = Whatsapp.contact();
        DataLayer.clickEvent({
          element: 'converse-com-a-fiat',
          elementCategory: 'whatsapp',
          pageSection: 'conteudo',
          pageSubsection: 'float',
          path: 'home',
        });

        window.open(url, '_blank', 'noopener,noreferrer');
      }}
    >
      <img src={brand.whatsBtn} className="whatsapp_flag" alt={id} />
      <span>Quero negociar</span>
    </button>
  );
};

export default WhatsappBtn;
