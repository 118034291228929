import {
  IncludePrefixResponsiveSizes,
  includeResponsive,
} from "@/utils/imagePrefixes";

const VERSIONS_PREFIX = "Versions/";

interface VideoProps {
  desktop: string;
  mobile: string;
}
export interface GalleryAsset {
  id: string;
  title: string;
  asset: IncludePrefixResponsiveSizes;
  mobile: IncludePrefixResponsiveSizes;
  video?: VideoProps;
  alt: string;
}

export interface IFeature {
  desktop: IncludePrefixResponsiveSizes;
  mobile: IncludePrefixResponsiveSizes;
  alt: string;
  title: string;
  label: string;
}

export interface IMainImage {
  desktop: IncludePrefixResponsiveSizes;
  mobile: IncludePrefixResponsiveSizes;
  alt: string;
  title: string;
}
interface ImagesAssets {
  main: IMainImage;
  features: IFeature[];
}

interface VersionsAssets {
  [key: string]: ImagesAssets;
}


export const useVersionsAssets = (versionNameColor?: string) => {
  const color = versionNameColor ?? "ff0000";

  const versionsAssets: VersionsAssets = {
    ultra: {
      main: {
        desktop: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/ultra/principal/fiat-toro-ultra-${color}`,
          "webp"
        ),
        mobile: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/ultra/principal/fiat-toro-ultra-${color}`,
          "webp"
        ),
        alt: "Fiat Toro Ultra da cor vermelha com grade preta vista frontal e lateral.",
        title : "Nova Fiat Toro Ultra"
      },
      features: [
        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/capota-rigida`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/capota-rigida`,
            "webp"
          ),
          alt: "Fiat Toro versão ultra vista traseira com capota rígida instalada em sua carroceria. ",
          title: "Capota da Toro Ultra",
          label: "Capota rígida",
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/central-multimidia-10`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/central-multimidia-10`,
            "webp"
          ),
          alt: "Vista interna de Fiat Toro Ultra e sua central multimidia de 10,1’’ com detalhes na cor preta.",
          title: "Central Multimidia Toro Ultra",
          label: "Central multimidia 10.1”",
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/santo-antonio-integrado`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ultra/features/santo-antonio-integrado`,
            "webp"
          ),
          alt: "Visão traseira de Fiat Toro Ultra na cor vermelha com Santo Antônio Integrado.",
          title: "Santo Antônio Integrado",
          label: "Santo Antonio integrado",
        },
      ],
    },

    ranch: {
      main: {
        desktop: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/ranch/principal/fiat-toro-ranch-${color}`,
          "webp"
        ),
        mobile: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/ranch/principal/fiat-toro-ranch-${color}`,
          "webp"
        ),
        alt: "Fiat Toro Ultra da cor vermelha com grade frontal diferenciada vista frontal e lateral.",
        title: "Nova Fiat Toro Ranch"
      },
      features: [
        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/banco-couro-personalizado`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/banco-couro-personalizado`,
            "webp"
          ),
          alt: "Visão interna de Fiat Toro Ranch com bancos em couro na cor marrom.",
          title: "Bancos Couro Toro Ranch",
          label: "Bancos em couro exclusivo",
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/central-multimidia-10`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/central-multimidia-10`,
            "webp"
          ),
          alt: "Vista interna de Fiat Toro Ultra e sua central multimidia de 10,1” com detalhes na cor preta",
          title: "Central Multimidia Toro Ultra",
          label: "Central multimidia 10.1”",
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/grade-frontal-diferenciada`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/ranch/features/grade-frontal-diferenciada`,
            "webp"
          ),
          title: "Grade Frontal Diferenciada",
          alt: "​Visão frontal de Fiat Toro Ultra com grade frontal diferenciada.",
          label: "Grade frontal exclusiva",
        },
      ],
    },

    volcano: {
      main: {
        desktop: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/volcano/principal/fiat-toro-volcano-${color}`,
          "png"
        ),
        mobile: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/volcano/principal/fiat-toro-volcano-${color}`,
          "png"
        ),
        alt: "Fiat Toro Volcano da cor vermelha com grade preta vista frontal e lateral.",
        title: "Nova Fiat Toro Volcano",
      },
      features: [
        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/banco-eletrico-motorista`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/banco-eletrico-motorista`,
            "webp"
          ),
          alt: `Interior de Fiat Toro Volcano com destaque para bancos com ajuste elétrico na cor ${color}.`,
          title: "Banco Elétrico Motorista",
          label: "Banco eletrico motorista"
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/roda-liga-leve-18`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/roda-liga-leve-18`,
            "webp"
          ),
          alt: `Vista lateral de Fiat Toro Volcano na cor ${color} com destaque para rodas de liga-leve aro 18.`,
          title: "Roda de liga-leve aro 18",
          label: "Roda liga-leve 18"
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/wireless-charger`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/volcano/features/wireless-charger`,
            "webp"
          ),
          label: "Wireless charger",
          title: "Carregador sem fio",
          alt: "Interior de Fiat Toro Volcano com destaque para carregador de smartphone sem fio.",
        },
      ],
    },

    freedom: {
      main: {
        desktop: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/freedom/principal/fiat-toro-freedom-${color}`,
          "webp"
        ),
        mobile: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/freedom/principal/fiat-toro-freedom-${color}`,
          "webp"
        ),
        alt: "Fiat Toro Freedom da cor vermelha com grade preta vista frontal e lateral.",
        title: "Nova Fiat Toro Freedom"
      },
      features: [
        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/central-multimidia-8`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/central-multimidia-8`,
            "webp"
          ),
          alt: "Vista interna de Fiat Toro Freedom e sua central multimidia de 8,4 polegadas com detalhes na cor preta.",
          title: "Central Multimidia Toro Freedom",
          label: "Central multimidia 8,4”"
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/farol-full-led`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/farol-full-led`,
            "webp"
          ),
          alt: "Vista frontal de Fiat Toro Freedom na cor vermelha com destaque para farol full led.",
          title: "Farol Full Led Toro Freedom",
          label: "Farol full led"
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/pneus-atr`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/freedom/features/pneus-atr`,
            "webp"
          ),
          label: "Ar-condicionado digital",
          title: "Ar-condicionado Toro Freedom",
          alt: "Visão interna de Fiat Toro Freedom com controles de ar-condicionado digital.",
        },
      ],
    },

    endurance: {
      main: {
        desktop: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/endurance/principal/fiat-toro-endurance-${color}`,
          "webp"
        ),
        mobile: includeResponsive(
          `${VERSIONS_PREFIX}/desktop/endurance/principal/fiat-toro-endurance-${color}`,
          "webp"
        ),
        alt: "Fiat Toro Endurance da cor vermelha vista frontal e lateral.",
        title: "Nova Fiat Toro Endurance"
      },
      features: [
        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/cluster-full-digital-7`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/cluster-full-digital-7`,
            "webp"
          ),
          alt: "Interior de Fiat Toro Endurance com painel de controle totalmente digital.",
          title: "Painel de controle Digital",
          label: "Cluster full digital-7",
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/motor-turbo-flex-270`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/motor-turbo-flex-270`,
            "webp"
          ),
          alt: "Motor de Fiat Toro Endurance em fundo preto.",
          title: "Motor Turbo Flex 270",
          label: "Motor turbo flex 270"
        },

        {
          desktop: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/transmissao-automatica`,
            "webp"
          ),
          mobile: includeResponsive(
            `${VERSIONS_PREFIX}/desktop/endurance/features/transmissao-automatica`,
            "webp"
          ),
          label: "Transmissao automatica",
          alt: "",
          title: ""
        },
      ],
    },
  };

  return versionsAssets;
};
