import { connectMe as connectMeAssets } from "@/assets/connectMe";
import { IncludePrefixResponsiveSizes } from "@/utils/imagePrefixes";

export interface ConnectMeSlide {
  id: string;
  title: string;
  titleElement?: JSX.Element;
  descriptionDesktop: JSX.Element;
  descriptionMobile: JSX.Element;
  image: IncludePrefixResponsiveSizes;
  background: IncludePrefixResponsiveSizes;
  video?: boolean;
  videoId?: string;
}

export const connectMe: ConnectMeSlide[] = [
  {
    id: "cme-c-1",
    title: "Operações remotas",
    titleElement: (
      <>
        {"operações"}
        <br />
        {"remotas"}
      </>
    ),
    descriptionDesktop: (
      <p>
        Que tal já entrar na sua Toro com uma climatização bem fresquinha?{" "}
        <br />
        Controle seu carro remotamente pelo celular, ligue e desligue ar-
        <br />
        condicionado, trave e destrave as portas e mais.
      </p>
    ),
    descriptionMobile: (
      <p>
        Que tal já entrar na sua Toro com uma <br />
        climatização bem fresquinha? Controle seu carro <br />
        remotamente pelo celular, ligue e desligue ar-
        <br />
        condicionado, trave e destrave as portas e mais.
      </p>
    ),
    image: connectMeAssets.d01,
    background: connectMeAssets.background01,
    video: true,
    videoId:"VS2csKjCvZo"
  },
  {
    id: "cme-c-2",
    title: "Assistenet virttual",
    titleElement: (
      <>
        Assistente <br />
        Virtual
      </>
    ),
    descriptionDesktop: (
      <p>
        Com a ajuda do serviço de assistência virtual do Connect////Me, <br /> você
        fica sabendo do status do seu carro num instante!
      </p>
    ),
    descriptionMobile: (
      <p>
        Com a ajuda do serviço de assistência <br />
        virtual do Connect////Me, você fica <br />
        sabendo do status do seu carro num instante!
      </p>
    ),
    image: connectMeAssets.d02,
    background: connectMeAssets.d02,
    video:true,
    videoId: "Orjqe5DYoL8"
  },
  {
    id: "cme-c-3",
    title: "Alerta preventivo de furto",
    titleElement: (
      <>
        ALERTA PREVENTIVO <br />
        DE FURTO
      </>
    ),
    descriptionDesktop: (
      <p>
        Receba alertas em tempo real em casos de desconexão da bateria, alarme
        disparado e transporte do carro sem a chave.
      </p>
    ),
    descriptionMobile: (
      <p>
        Receba alertas em tempo real em casos <br /> de desconexão da bateria,
        alarme disparado <br /> e transporte do carro sem a chave.
      </p>
    ),
    image: connectMeAssets.d01,
    background: connectMeAssets.d01,
    video:true,
    videoId: "vz3uwP6Kcjk"
  },
  {
    id: "cme-c-4",
    title: "Alertas de condução",
    titleElement: (
      <>
        Alertas de <br />
        condução
      </>
    ),
    descriptionDesktop: (
      <p>
        Determine itens como limite de velocidade e perímetro de rodagem, <br />
        recebendo alertas de condução direto no seu celular.
      </p>
    ),
    descriptionMobile: (
      <p>
        Determine itens como limite de velocidade <br /> e perímetro de rodagem,
        recebendo alertas <br />
        de condução direto no seu celular.
      </p>
    ),
    image: connectMeAssets.d03,
    background: connectMeAssets.d03,
    video:true,
    videoId: "cRQ5BuRZqzg"
  },
  {
    id: "cme-c-5",
    title: "Chamada de emergência",
    titleElement: (
      <>
        Chamada <br /> de emergência
      </>
    ),
    descriptionDesktop: (
      <p>
        Em casos de colisão severa, os airbags e o pré-tensionamento do cinto <br /> de
        segurança são acionados. Além disso, sua Toro realiza <br /> automaticamente
        uma chamada de emergência.
      </p>
    ),
    descriptionMobile: (
      <p>
        Em casos de colisão severa, os airbags <br /> e o pré-tensionamento do
        cinto de segurança
        <br /> são acionados. Além disso, sua Toro realiza <br />
        automaticamente uma chamada de emergência.
      </p>
    ),
    image: connectMeAssets.d04,
    background: connectMeAssets.d04,
    video:true,
    videoId: "M2PXsqI7_dY"
  },
  {
    id: "cme-c-6",
    title: "Wi-fi Hotspot",
    titleElement: (
      <>
        Wi-fi <br /> Hotspot
      </>
    ),
    descriptionDesktop: (
      <p>
        Celular, laptop, tablet… Até 8 aparelhos podem ser conectados <br />
        simultaneamente na rede Wi-Fi da sua Toro.
      </p>
    ),
    descriptionMobile: (
      <p>
        Celular, laptop, tablet… Até 8 <br /> aparelhos podem ser conectados
        simultaneamente na rede <br /> Wi-Fi da sua Toro.
      </p>
    ),
    image: connectMeAssets.d06,
    background: connectMeAssets.d06,
    video:true,
    videoId: "0S-GUhz0elk"
  }
];
