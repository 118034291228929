import { FC, useContext } from 'react';
import styles from './Cards.module.scss';
import { CardsItemsProps } from '@/models';
import { observer } from 'mobx-react-lite';
import scssStyles from '@/utils/scssStyles';
import { Context } from '@/context';
import ResponsiveLazyImage from '../responsive-lazy-image';
import PlusThumb from '../svg-components/PlusThumb';
import DataLayer from '@/utils/Datalayer';
import Image from 'next/image';

export interface CardsProps {
  items: CardsItemsProps[];
}

const Cards: FC<CardsProps> = observer(({ items }) => {
  const { state, dispatch } = useContext(Context);

  const handleSetMainDisplayIndex = (index: number) => {
    dispatch({
      type: 'SET_MAIN_DISPLAY_INDEX',
      payload: { mainDisplayIndex: index },
    });
  };

  return (
    <div className={styles.positionFix}>
      <div className={styles.container}>
        <div className={styles.cards}>
          {items.map((item, index) => {
            return (
              <button
                key={`desktop-stories-button-${index}`}
                onClick={() => {
                  DataLayer.clickEvent({
                    element: `${item.titleTag}`,
                    elementCategory: 'card',
                    pageSection: 'conteudo',
                    pageSubsection: 'nova-toro',
                  });
                  handleSetMainDisplayIndex(index + 1);
                }}
                className={styles.unitaryBox}
                title={item.title2}
              >
                <div
                  className={scssStyles([
                    styles.polygonBorder,
                    state.layout.mainDisplayIndex === index + 1
                      ? styles.polygonActive
                      : '',
                  ])}
                />
                <div className={scssStyles([styles.polygonFormater])}>
                  <div
                    id={'thumb' + item.id}
                    className={scssStyles([
                      styles.externalBox,
                      index === state.layout.mainDisplayIndex - 1
                        ? styles.selected
                        : '',
                    ])}
                  >
                    <PlusThumb
                      className={scssStyles([
                        styles.plus,
                        state.layout.mainDisplayIndex === index + 1
                          ? styles.hidde
                          : '',
                      ])}
                    />
                    <div className={styles.cardTitleBox}>
                      <p
                        className={scssStyles([
                          styles.cardTitle,
                          state.layout.mainDisplayIndex !== index + 1
                            ? ''
                            : styles.selected,
                        ])}
                      >
                        {item.title}
                      </p>
                    </div>
                  </div>
                  <Image
                    src={item.cardSrc.fullPath2x}
                    alt={item.altTag}
                    layout="fill"
                    className={styles.cardImage}
                  />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Cards;
