import { includeResponsive } from "@/utils/imagePrefixes";

const PREFIX = `${process.env.BASE_PREFIX}images/`;
const SECTION_PREFIX = 'ConnectMe/';

export const connectMe = {
  background01: includeResponsive(`${SECTION_PREFIX}toro-ultra-multimidia`, "webp", {
    prefix: PREFIX,
  }),
  d01: includeResponsive(`${SECTION_PREFIX}assistente-virtual`, "webp", {
    prefix: PREFIX,
  }),
  d02: includeResponsive(`${SECTION_PREFIX}operacoes-remotas`, "webp", {
    prefix: PREFIX,
  }),
  d03: includeResponsive(`${SECTION_PREFIX}alertas-conducao`, "webp", {
    prefix: PREFIX,
  }),
  d04: includeResponsive(`${SECTION_PREFIX}chamadas-emergencia`, "webp", {
    prefix: PREFIX,
  }),
  d05: includeResponsive(`${SECTION_PREFIX}alerta-furto`, "webp", {
    prefix: PREFIX,
  }),
  d06: includeResponsive(`${SECTION_PREFIX}wifi-hotspot`, "webp", {
    prefix: PREFIX,
  }),
};
