import { Context } from "@/context";
import { defaultColors, useCarsVersionsData } from "@/data/versions.data";
import { useContext, useEffect, useState } from "react";
import styles from "./color-changer.module.scss";
import formatString from "@/utils/formatString";
import DataLayer from "@/utils/Datalayer";

interface ColorButtonsProps {
  activeCarIndex: number;
  activeColorIndex?: number;
  setActiveColorIndex?: (colorIndex: number) => void;
}

export const ColorChanger: React.FC<ColorButtonsProps> = ({
  activeCarIndex,
}) => {
  const { state, dispatch } = useContext(Context);
  const [dataReady, setDataReady] = useState(false);
  const [colors, setColors] = useState<any[]>([]);

  const cars = useCarsVersionsData(state.layout.colorActive);

  const availableColors = async (mvsCode: string) => {
    const isUltra = mvsCode === "2261AP1";

    const dataColor = await fetch(
      `https://part-service.k8s.fcalatam.com.br/v2/color?mvsCode=${mvsCode}&year=${
        isUltra ? "2024" : "2025"
      }&market=pt-BR`
    );
    const colorResponse = await dataColor.json();

    cars.map((item, index) => {
      if (item.mvsCode === mvsCode) {
        colorResponse.map((value: { rgb: string; name: string }, i: number) => {
          if (formatString(value.rgb) === "ff000") {
            dispatch({
              type: "SET_COLOR_ACTIVE",
              payload: {
                colorActive: formatString(value.rgb),
              },
            });
          }
          cars[index].colors.push({
            colorName: value.name,
            haxCode: value.rgb,
            colorNameFormatted: formatString(value.name),
            pathSrc: item.src.desktop.fullPath,
            colorActive: formatString(value.rgb) === state.layout.colorActive,
          });

          if (item.id === state.layout.currentVersion.id) {
            setColors((e) => [...e, value]);
          }
        });
      }
    });

    return dataColor;
  };

  useEffect(() => {
    if (!state.layout.colorActive) {
      dispatch({
        type: "SET_COLOR_ACTIVE",
        payload: {
          colorActive: "ff0000",
        },
      });
    }
  }, [state.layout.colorActive, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `
          https://mvs-service.k8s.fcalatam.com.br/mvs?brand=FIAT&model=226&year=2025`
      );
      const json = await data.json();

      json.map((item: { code: string }) => {
        setDataReady(true);
        availableColors(item.code);
      });
    };

    fetchData();
  }, []);

  const handleColorClick = (
    colorNameFormatted: string,
    colorCode: string
  ): void => {
    dispatch({
      type: "SET_COLOR_ACTIVE",
      payload: {
        colorActive: formatString(colorNameFormatted),
      },
    });

    dispatch({
      type: "SET_CURRENT_VERSION",
      payload: {
        currentVersion: state.layout.currentVersion,
      },
    });

    // "/images/Versions//desktop/ultra/principal/fiat-toro-ultra-vermelho-colorado.webp"
    dispatch({
      type: "SET_VERSION_PHOTO_SRC",
      payload: {
        versionPhotoSrc: `images/Versions/${cars[activeCarIndex].slug}/${colorCode}.webp`,
      },
    });
  };

  return (
    <div className={styles.colorsContainer}>
      <p>Selecione a cor da Fiat Toro </p>
      <div className={styles.colors}>
        <nav>
          {colors.length < 1
            ? defaultColors.map((color, i) => {
                const active =
                  state.layout.colorActive === formatString(color.rgb);
                return (
                  <button
                    className={active ? styles.active : " "}
                    key={i}
                    onClick={() => {
                      handleColorClick(color.rgb, color.rgb);
                    }}
                  >
                    <span
                      className={styles.currCircleColor}
                      style={{
                        background: `#${color.rgb}`,
                      }}
                      aria-label={color.name}
                      title={color.name}
                    />
                    <span className={styles.currTextColor}>{color.name}</span>
                  </button>
                );
              })
            : colors.map((color, i) => {
                const active =
                  state.layout.colorActive === formatString(color.rgb);
                return (
                  <button
                    className={active ? styles.active : " "}
                    key={i}
                    onClick={() => {
                      DataLayer.selectEvent({
                        element: color.name,
                        elementCategory: "icone",
                        pageSection: "versao",
                        pageSubsection: state.layout.currentVersion.slug,
                      });
                      handleColorClick(color.rgb, color.rgb);
                    }}
                  >
                    <span
                      className={styles.currCircleColor}
                      style={{
                        background: `#${color.rgb}`,
                      }}
                      aria-label={color.name}
                      title={color.name}
                    />
                    <span className={styles.currTextColor}>{color.name}</span>
                  </button>
                );
              })}
        </nav>
      </div>
    </div>
  );
};
