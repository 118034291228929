import { FC } from "react";

const PlusThumb: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="27.126"
      viewBox="0 0 32 27.126"
      className={className}
    >
      <g id="plus" transform="translate(-104 -4.874)">
        <path
          id="plus-2"
          data-name="plus"
          d="M0,4.874H32V32H0Z"
          transform="translate(104)"
          fill="#fc1430"
        />
        <g id="Grupo_4609" data-name="Grupo 4609" transform="translate(0 7)">
          <path
            id="Caminho_4858"
            data-name="Caminho 4858"
            d="M1371-2650v-6h-6v-2h6v-6h2v6h6v2h-6v6Z"
            transform="translate(-1255 2668)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default PlusThumb;
