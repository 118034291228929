import {
  dataMenuButtons,
  dataMenuButtonsMobile,
  dataMenuLabelMobile,
  dataMenuLabels,
} from "@/data/menu.data";
import { useMobxStores } from "@/store";
import RampageStore from "@/store/RampageStore";
import scssStyles from "@/utils/scssStyles";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { CTAButton, Conditional } from "..";
import styles from "./navigation.module.scss";
import { Context } from "@/context";
import { brand } from "@/assets";
import productDetails, { ProductTab } from "@/data/product-details.data";
import { brandLinks } from "@/constants";
import DataLayer from "@/utils/Datalayer";

const Navigation: FC = observer(() => {
  const store: RampageStore = useMobxStores();
  const { state, dispatch } = useContext(Context);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleSelectedDetailTab = (
    tab: ProductTab | undefined | null
  ): void => {
    if (tab) {
      dispatch({ type: "SET_CURRENTLY_VISIBLE_NAV", payload: { tab: tab } });
    }

    dispatch({
      type: "SET_DETAIL_TAB",
      payload: {
        isMobile: true,
        selectedDetailTab: tab,
      },
    });
  };
  const handleStore = async (tabReference: ProductTab): Promise<void> => {
    if (tabReference.parentSlug === "tudo-sobre-rampage") {
      dispatch({
        type: "SET_CURRENTLY_VISIBLE_NAV",
        payload: { tab: tabReference },
      });
    }

    await dispatch({
      type: "SET_CURRENTLY_VISIBLE_NAV",
      payload: { tab: tabReference },
    });
    await dispatch({
      type: "SET_SELECTED_DETAIL_TAB",
      payload: { selectedDetailTab: tabReference },
    });
    await dispatch({ type: "SWIPE_TO_TAB", payload: { tab: tabReference } });
  };

  return (
    <>
      {state.layout.menuOpen && (
        <div
          className={styles.gestureDetector}
          onClick={() => {
            DataLayer.closeEvent({
              elementCategory: "imagem",
              element: "menu",
              pageSection: "header",
              pageSubsection: "menu",
            });
            dispatch({
              type: "SET_MENU_OPEN",
              payload: { menuOpen: false },
            });
          }}
        />
      )}
      <nav
        id="mainNav"
        className={scssStyles([
          styles.container,
          state.layout.menuOpen ? styles.open : "",
        ])}
        style={{
          // backgroundColor: !state.layout.isDesktop ? '#292929' : offset > 10 || state.layout.menuColor ? '#363636' : 'transparent',
          transition: "background-color 0.5s ease",
        }}
      >
        <div className={styles.scroller}>
          <Conditional notOn="mobile">
            <a href={brandLinks.mainUrl} target="_blank">
              <div className={styles.productLogo}>
                <img src={brand.logoNav} alt={"fiat"} />
              </div>
            </a>
          </Conditional>

          <Conditional notOn={"desktop"}>
            {dataMenuLabelMobile().map((item, index) => {
              const active = item.id === state.layout.currentlyVisibleNav;
              return (
                <div className={styles.menuItem} key={`menuItem-${item.id}`}>
                  <a
                    key={item.id}
                    className={scssStyles([
                      styles.menuLinks,
                      active ? styles.active : "",
                    ])}
                    href={item.url}
                    onClick={(e) => {
                      if (item.reference) {
                        DataLayer.clickEvent({
                          elementCategory: "link",
                          element: item.label,
                          pageSection: "header",
                          pageSubsection: "menu",
                        });
                        dispatch({
                          type: "SCROLL_TO_REFERENCE",
                          payload: { reference: item.reference },
                        });
                        const tabReference = productDetails.find(
                          (p) => p.slug === item.slug
                        );
                        if (tabReference) {
                          handleSelectedDetailTab(
                            state.layout.selectedDetailTab === tabReference.id
                              ? null
                              : tabReference
                          );
                        }
                        dispatch({
                          type: "SET_MENU_OPEN",
                          payload: { menuOpen: false },
                        });
                      } else {
                        if (index === 0) {
                          DataLayer.clickEvent({
                            elementCategory: "link",
                            element: item.label,
                            pageSection: "header",
                            pageSubsection: "menu",
                          });
                          window?.scrollTo({ top: 0, behavior: "smooth" });
                        } else {
                          DataLayer.clickEvent({
                            elementCategory: "link",
                            element: item.label,
                            pageSection: "header",
                            pageSubsection: "menu",
                          });
                          dispatch({
                            type: "SCROLL_TO_REFERENCE",
                            payload: { reference: item },
                          });
                          store.scrollToRef(item);
                        }
                        dispatch({
                          type: "SET_MENU_OPEN",
                          payload: { menuOpen: false },
                        });
                      }

                      e.preventDefault();
                    }}
                  >
                    <span className={scssStyles([styles.linkUnderline])}>
                      {item.label}
                    </span>
                  </a>
                  <img
                    className={scssStyles([
                      styles.teste1,
                      active ? styles.visible : "",
                    ])}
                    src={""}
                    alt=""
                  />
                </div>
              );
            })}

            {!!dataMenuButtonsMobile.length && (
              <div className={styles.buttonsHolder}>
                {dataMenuButtonsMobile.map((item) => {
                  return (
                    <CTAButton
                      key={`menu-cta-${item.id}`}
                      href={item.url}
                      className={styles.menuButton}
                      text={item.label}
                      width="88%"
                      title={item.label}
                      iconCode={item.iconCode}
                      handleClick={() => DataLayer.clickEvent({
                        elementCategory: "cta",
                        element: item.label,
                        pageSection: "header",
                        pageSubsection: "menu",
                      })}
                    />
                  );
                })}
              </div>
            )}
          </Conditional>
          <Conditional notOn={"mobile"}>
            <div className={styles.apart}>
              <div className={styles.menuItemArea}>
                {dataMenuLabels()
                  .filter((value) => value.numberId < dataMenuLabels().length)
                  .map((item, index) => {
                    const reference = state.layout.currentlyVisibleNav;
                    const active = item.id === reference;

                    return (
                      <div
                        className={styles.menuItem}
                        key={`menuItem-${item.id}`}
                      >
                        <a
                          key={item.id}
                          className={scssStyles([
                            styles.menuLinks,
                            active ? styles.active : "",
                          ])}
                          href={`/toro${item.url}`}
                          onClick={(e) => {
                            DataLayer.clickEvent({
                              elementCategory: "link",
                              element: item.label,
                              pageSection: "header",
                              pageSubsection: "menu",
                            });
                            if (index === 0) {
                              window?.scrollTo({ top: 0, behavior: "smooth" });
                            } else {
                              dispatch({
                                type: "SCROLL_TO_REFERENCE",
                                payload: { reference: item },
                              });
                            }
                            dispatch({
                              type: "SET_MENU_OPEN",
                              payload: { menuOpen: false },
                            });

                            e.preventDefault();
                          }}
                        >
                          <span className={scssStyles([styles.linkUnderline])}>
                            {item.label}
                          </span>
                        </a>
                      </div>
                    );
                  })}
              </div>

              {!!dataMenuButtons.length && (
                <div className={styles.buttonsHolderDesktop}>
                  {dataMenuButtons.map((item) => {
                    return (
                      <CTAButton
                        key={`menu-cta-${item.id}`}
                        href={item.url}
                        variant="primary"
                        className={styles.menuButtonDekstop}
                        text={item.label}
                        title={item.label}
                        color={item.color}
                        excludeArrow={false}
                        handleClick={() => DataLayer.clickEvent({
                          elementCategory: "cta",
                          element: item.label,
                          pageSection: "header",
                          pageSubsection: "menu",
                        })}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </Conditional>
        </div>
      </nav>
    </>
  );
});

export default Navigation;
